import React from "react";

const Cooky = () => {
  return (
    <div className="container py-40 mx-auto px-4">
      <h1 className="text-left font-[400] text-[24px] leading-[28px] text-[#1F1F39] font-poppins pb-[50px]">
        Cookies
      </h1>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        To make this site work properly, we sometimes place small data files
        called cookies on your device. Most big websites do this too.
      </p>
      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px]">
        What are cookies?
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        A cookie is a small text file that a website saves on your computer or
        mobile device when you visit the site. It enables the website to
        remember your actions and preferences (such as login, language, font
        size and other display preferences) over a period of time, so you don’t
        have to keep re-entering them whenever you come back to the site or
        browse from one page to another.
      </p>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        How do we use cookies?
      </p>

      <li className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We use cookies to remember users' actions, to identify the user, for
        online behavioural advertising)
      </li>
      <li className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Cookies are essential for the website or a given functionality to work
        or if they aim to enhance the performance of the website
      </li>
      <li className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        the types of cookies used (e.g. session or permanent, first or
        third-party)
      </li>
      <li className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        teenyfy.com & its advertising partner (third party website)
        controls/accesses the cookie-related information.
      </li>
      <li className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Cookies will not be used for any purpose other than the one stated
      </li>

      <p className="text-left font-[600] text-[16px] leading-[20px] text-gray font-open-sans pb-[15px] pt-[20px]">
        How long does Teenyfy retain Personal Information ?
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans">
        You can control and/or delete cookies as you wish – for details, see{" "}
        <a
          rel="noreferrer"
          href="https://www.aboutcookies.org/"
          target="_blank"
          className="text-dark-green"
        >
          aboutcookies.org
        </a>
        . You can delete all cookies that are already on your computer and you
        can set most browsers to prevent them from being placed. If you do this,
        however, you may have to manually adjust some preferences every time you
        visit a site and some services and functionalities may not work.
      </p>
    </div>
  );
};

export default Cooky;
