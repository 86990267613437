import React from "react";
import Cooky from "../components/cookie/cookie";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const index = () => {
  return (
    <Layout>
      <Seo slug="/cookie-policy/" />
      <Cooky />
    </Layout>
  );
};

export default index;
